@tailwind base;
@tailwind components;
@tailwind utilities;

.shadow-modal{
  --tw-shadow: 0 25px 50px 30px rgb(0 0 0 / 60%);
  --tw-shadow-colored: 0 25px 50px 30px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
